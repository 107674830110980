<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col
          cols="7"
          class="mt-2 ml-1 mb-0 pb-0 pr-0 mr-0"
          style="display: grid; align-content: center;"
        >
          <div
            style="display: grid; align-content: center;"
          >
            <span
              class="font-weight-bold text-h5"
              style="margin-bottom: 5px;"
            >{{ title }}</span>
          </div>
        </v-col>
      </v-row>
      <v-hover
        v-slot="{ hover }"
      >
        <div>
          <v-row
            v-for="(posting, index) in postings"
            :key="index"
            class="mb-0 mt-2 pl-1 pr-1"
          >
            <v-col
              cols="12"
              :class="`widget ${hover ? 'custom-color' : 'white'} pa-4 pt-3`"
            >
              <v-row>
                <v-col
                  class="pb-1 pt-2 flex-grow-1 flex-shrink-0"
                  cols="6"
                >
                  <span
                    class=" text-button"
                    style="color: rgb(0, 145, 255);"
                  >{{ status }}</span>
                  <!-- <v-btn x-small color="success"></v-btn> -->
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-if="applied"
                        color="success"
                        class="mb-2 mr-2 ml-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-check-circle
                      </v-icon>
                    </template>
                    <span>Applied</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  class="pb-1 pt-3 flex-grow-1 flex-shrink-0 d-flex justify-end"
                  cols="6"
                >
                  {{ expireStatus(posting) }}
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <span class="text-h6 font-weight-bold mr-2">{{ positionName(posting) }} - #{{ posting.id }}</span>
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <template v-if="posting.matchingSkill && posting.matchingSkill.length">
                    <span
                      class="text-subtitle-2 font-weight-regular success--text"
                    >MATCHING YOUR SKILLS</span> <br>
                    <v-chip
                      v-for="(skill, index) in posting.matchingSkill"
                      :key="'skill-chip-' + index"
                      color="primary"
                      class="mr-1 mb-0 mt-1"
                    >
                      {{ '#' + skill.name }}
                      <v-icon
                        right
                        class="mr-0"
                        color="success"
                      >
                        mdi-check
                      </v-icon>
                    </v-chip>

                    <br>
                  </template>
                  <div
                    class="text-subtitle-2 font-weight-regular mt-2"
                  >
                    {{ posting.matchingSkill && posting.matchingSkill.length ? 'ADDITIONAL ' : '' }}REQUIRED SKILLS
                  </div>
                  <v-chip
                    v-for="(skill, index) in posting.candidateSkill"
                    :key="'posting-' + posting.id + 'skill-chip-' + index"
                    color="primary"
                    class="mr-1 mb-0 mt-1"
                  >
                    {{ '#' + skill.name }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-hover>
      <v-row
        v-if="postings.length == 0"
      >
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <span class="text-h6">
            None
          </span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';

export default {
  components: {
  },

  props:
  {
    charity: {
      type: Object,
      default: () => {},
    },
    applied: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    postings: [],
    total: 0,
    title: 'Charity Postings',
    loadingCharityPostings: false,
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      involvement: (state) => state.user.selectedInv,
      candidate: (state) => state.candidate.candidate,
      storeCharity: (state) => state.charity.charity,
    }),
    isCandidate() {
      return this.isLoggedIn && this.involvement && this.involvement.involvement === 'candidate' && this.candidate;
    },
    status() {
      // if (this.charityPosting.expiryDate < this.$moment().format('YYYY-MM-DD')) return 'EXPIRED';
      return 'OPEN POSITION';
    },
  },
  watch: {
    charity: {
      immediate: true,
      handler(value) {
        if (!value && !this.editable) return;
        this.getPostings();
      },
    },
  },
  methods: {
    openCharityPosting() {
      // this.$emit('showCharityPosting', this.charityPosting.id);
      // this.$router.push(`/charity-profile?cid=${this.charity.id}`);
    },
    async getPostings(reset = true) {
      if (reset && this.loadingCharityPostings) return;
      this.loadingCharityPostings = true;
      if (reset) {
        this.postings = [];
      }
      const filters = [];
      filters.push({ name: 'charityId', values: [this.charity.id.toString()] });
      filters.push({ name: 'expire', values: [] });
      this.loading = true;
      const result = await this.$api.charityPostings.read(
        createPaginationObject(null, null, filters),
      );
      this.loading = false;
      if (!result) {
        this.postings = [];
        this.total = 0;
        return;
      }
      result.postings.forEach((charityPosting) => {
        const copiedPosting = { ...charityPosting, matchingSkill: [], candidateSkill: [] };
        if (this.isCandidate) {
          charityPosting.candidateSkill.forEach((skill) => {
            if (this.candidate.skills.findIndex((x) => x.id === skill.id) > -1) {
              copiedPosting.matchingSkill.push(skill);
            } else {
              copiedPosting.candidateSkill.push(skill);
            }
          });
        } else {
          copiedPosting.candidateSkill = charityPosting.candidateSkill;
        }

        this.postings.push(copiedPosting);
      });
      this.$emit('postingsLoaded', [this.postings]);
      this.total = result.total;
      this.loadingCharityPostings = false;
    },
    expireStatus(posting) {
      let expires = '';
      const elapsedDays = Math.abs(this.$moment().diff(this.$moment(posting.expiryDate), 'days'));
      const elapsedMonths = Math.abs(this.$moment().diff(this.$moment(posting.expiryDate), 'months'));
      const elapsedYears = Math.abs(this.$moment().diff(this.$moment(posting.expiryDate), 'years'));
      if (elapsedDays < 31) expires = `${elapsedDays} Day${elapsedDays > 1 ? 's' : ''}`;
      else if (elapsedMonths < 13) expires = `${elapsedMonths} Month${elapsedMonths > 1 ? 's' : ''}`;
      else expires = `${elapsedYears} Year${elapsedYears > 1 ? 's' : ''}`;
      if (posting.expiryDate < this.$moment().format('YYYY-MM-DD')) {
        expires = `Expired ${expires} ago`;
      } else {
        expires = `${expires} left`;
      }
      return expires;
    },
    positionName(posting) {
      const { name } = posting.charityPosition;
      // if (name.length > 16) name = `${name.substring(0, 16)}...`;
      return name;
    },
  },
};
</script>
<style>
  .widget{
    border-radius: 10px;
    padding: 12px;
  }
  .thumbnail-image {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .hovering {
    background-color: transparent !important;
    background-color: #E0E0E0 !important;
    cursor: pointer;
  }
  .custom-color {
   background-color: #d8f2e0;
   cursor: pointer;
  }
</style>
