var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"pa-2 pb-2 ma-2 mt-12",staticStyle:{"background-color":"white","border-radius":"8px"}},[_c('v-row',{staticClass:"text-left"},[_c('v-col',{staticClass:"pa-6",attrs:{"cols":"12"}},[_c('h1',[_vm._v("Search")])])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"placeholder":"Search for Candidates & Charities..","filled":"","rounded":"","hide-details":"","large":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticClass:"pa-2 pb-2 ma-2 mt-12",staticStyle:{"background-color":"white","border-radius":"8px"}},[_c('candidate-table',{attrs:{"search":_vm.search,"filters":_vm.filters,"title":"Candidates"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover mr-2",attrs:{"icon":['fabs', 'user-pen']},on:{"click":function($event){return _vm.authAs(item)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Edit candidate details")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover mr-3",attrs:{"icon":['fabs', 'xmark']},on:{"click":function($event){return _vm.disableCandidate(item.id)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Deactivate candidate")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover",attrs:{"icon":['fabs', 'trash-can']},on:{"click":function($event){return _vm.CandidateSoftDelete(item.id)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Delete candidate")])])]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticClass:"pa-2 pb-2 ma-2 mt-12",staticStyle:{"background-color":"white","border-radius":"8px"}},[_c('charity-table',{attrs:{"search":_vm.search,"filters":_vm.filters,"title":"Charities"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover mr-3",attrs:{"icon":['fabs', 'users']},on:{"click":function($event){return _vm.openCharityRepForm(item)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Users")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover mr-3",attrs:{"icon":['fabs', 'chalkboard']},on:{"click":function($event){return _vm.openCharityPositionDialog(item)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Board")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover mr-3",attrs:{"icon":['fabs', 'layer-group']},on:{"click":function($event){return _vm.openCharityPostingDialog(item)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Postings")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"icon-hover mr-3",attrs:{"icon":['fabs', 'trash-can']},on:{"click":function($event){return _vm.CharitySoftDelete(item.id)}}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Delete charity")])])]}}])})],1),_c('candidate-template-dialog',{ref:"candidateTemplateDialog",attrs:{"title":_vm.candidateDialogTitle,"user":_vm.user},on:{"candidate-success":_vm.notifySuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(_vm.currentUser != null && item != null && _vm.currentUser.id != item.id)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.authAs(item)}}},[_vm._v(" Log In As User ")]):_vm._e()]}}])}),_c('charity-rep-table-template-dialog',{ref:"charityRepTableTemplateDialog",attrs:{"title":_vm.charityRepsTableFormTitle,"is-board-match-admin":true}}),_c('charity-position-template-dialog',{ref:"charityPositionTemplateDialog",attrs:{"title":_vm.charityPositionDialogTitle,"charity":_vm.charity}}),_c('charity-posting-template-dialog',{ref:"charityPostingTemplateDialog",attrs:{"title":_vm.charityPostingDialogTitle,"charity":_vm.charity}}),_c('user-authenticate-as-dialog',{ref:"authenticateAsDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }