<template>
  <div
    style="background-color: #efefef;  width: 100%;"
  >
    <!-- <v-row
      v-if="editable"
    >
      <v-col
        class="d-flex justify-end"
      >
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="editable"
              fab
              large
              class="charity-fab"
              :color="editing ? 'success' : 'primary'"
              v-bind="attrs"
              v-on="on"
              @click="editFABClicked()"
            >
              <v-icon>
                {{ editing ? 'mdi-check' : 'mdi-pencil' }}
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{ editing ? 'Done Editing' : 'Edit Profile' }}
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row
      class=""
    >
      <v-col
        class="d-flex justify-center align-end pt-0"
        style="height: 130px; z-index: 3;"
      >
        <v-tooltip
          v-if="editing"
          top
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="edit-background-button"
              v-bind="attrs"
              @click="editBackground()"
              v-on="on"
            >
              <v-img
                width="125px"
                height="125px"
                contain
                :src="require('@/assets/edit-image-icon.png')"
              />
            </v-btn>
          </template>
          <span>Set Background Image</span>
        </v-tooltip>
      </v-col>
    </v-row> -->
    <div class="content-div">
      <v-row>
        <v-col
          class="ma-0 pb-0 pt-0 ml-0"
        >
          <template v-if="socialMediaLinks && socialMediaLinks.length > 0">
            <div class="social-media-widget white">
              <general-social-media-widget
                :links="socialMediaLinks"
              />
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col
          cols="12"
        >
          <div class="white title-widget">
            <charity-title-widget
              v-if="charity"
              ref="charityTitleWidget"
              :charity="charity"
              :editing="editing"
              :editable="editable"
              @imageLoaded="(image) => {profileImage = image}"
            />
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="charity"
      >
        <v-col
          cols="8"
        >
          <!--left section-->
          <v-row v-if="charity.mandate">
            <v-col>
              <div class="white widget">
                <general-text-widget
                  :title="'Mission'"
                  :html-text="charity.mandate"
                />
              </div>
            </v-col>
          </v-row>
          <v-row v-if="charity.vision">
            <v-col>
              <div class="white widget">
                <general-text-widget
                  :title="'Vision'"
                  :html-text="charity.vision"
                />
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="charity.charityPrograms
              &&
              charity.charityPrograms.length > 0"
          >
            <v-col>
              <div class="white widget">
                <charity-programs-widget
                  :charity="charity"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="4"
        >
          <!--right section-->
          <v-row v-if="isLoggedIn">
            <v-col>
              <div class="white widget">
                <charity-information-widget :charity="charity" />
              </div>
            </v-col>
          </v-row>
          <v-row v-show="showBoardMembers">
            <v-col>
              <div class="white widget">
                <charity-board-members-widget
                  :charity="charity"
                  @membersLoaded="(members) => processLoadedBoardMembers(members)"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="white widget">
                <charity-posting-widget
                  :charity="charity"
                  @postingsLoaded="(postings) => processLoadedCharityPosting(postings)"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <general-dialog
      v-if="charity"
      ref="backgroundImageSelectorDialog"
      title="Select or Upload Background Image"
      submit-button-label="Apply Selected Background"
      dialog-width="1750px"
      :submit-button-enabled="false"
      @submit="submitBackgroundImageChange()"
    >
      <charity-background-image-selector-template
        ref="backgroundImageSelector"
        :charity="charity"
        @close="closeDialogs()"
        @backgroundChanged="loadBackgroundImage()"
      />
    </general-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import GeneralTextWidget from '@/components/general/GeneralTextWidget';
import GeneralDialog from '@/components/general/GeneralDialog';
import CharityTitleWidget from '@/components/charity/profile/CharityTitleWidget';
import CharityBackgroundImageSelectorTemplate from '@/components/charity/profile/CharityBackgroundImageSelectorTemplate';
import { createPaginationObject } from '@/helpers/PaginationHelper.js';
import GeneralTextWidget from '@/components/general/GeneralTextWidget.vue';
import CharityProgramsWidget from '@/components/charity/profile/CharityProgramsWidget.vue';
import CharityInformationWidget from '@/components/charity/profile/CharityInformationWidget.vue';
import CharityBoardMembersWidget from '@/components/charity/profile/CharityBoardMembersWidget.vue';
import CharityPostingWidget from '@/components/charity/profile/CharityPostingWidget.vue';
import GeneralSocialMediaWidget from '@/components/general/GeneralSocialMediaWidget';

export default {
  components: {
    // GeneralTextWidget,
    GeneralDialog,
    CharityTitleWidget,
    CharityBackgroundImageSelectorTemplate,
    GeneralTextWidget,
    CharityProgramsWidget,
    CharityInformationWidget,
    GeneralSocialMediaWidget,
    CharityBoardMembersWidget,
    CharityPostingWidget,
  },
  props: {
    charityId: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    charity: null,
    backgroundImage: null,
    profileImage: null,
    editing: false,
    showPrograms: true,
    externalCharityId: null,
    showBoardMembers: false,
    showCharityPostings: false,
  }),
  computed: {
    ...mapState({
      storeUser: (state) => state.user,
      storeCharity: (state) => state.charity.charity,
      socialMediaTypes: (state) => state.general.socialMediaTypes,
      isLoggedIn: (state) => state.auth.isLoggedIn,
    }),
    charityStatus() {
      return 'Searching for Photos of Spiderman';
    },
    editable() {
      if (this.charity && this.storeCharity) {
        return this.charity.id === this.storeCharity.id;
      } return false;
    },
    socialMediaLinks() {
      const links = [];
      if (this.charity != null) {
        // if (this.charity.website != null && this.charity.website !== '' && this.profileImage) {
        //   links.push({
        //     id: -1, socialMediaTypeId: -1, url: this.charity.website, image: this.profileImage, socialMediaType: { name: 'Website' },
        //   });
        // }
        if (this.charity && this.charity.socialMedia && this.charity.socialMedia.length > 0) Array.prototype.push.apply(links, this.charity.socialMedia.filter(((x) => x.url != null && x.url !== '')));
      }
      return links;
    },
  },
  watch: {
    charityId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.loadCharity();
          this.loadBackgroundImage();
        }
      },
    },
  },

  created() {
    this.checkExternalCharityId();
    this.init();
  },

  methods: {
    async init() {
      this.loadTypes();
      this.loadCharity();
      this.loadBackgroundImage();
    },
    async loadCharity() {
      if (!this.externalCharityId && !this.charityId) return;
      const filters = [];
      filters.push({ name: 'charityId', values: [this.externalCharityId ? this.externalCharityId : this.charityId] });
      // const response = await this.$api.charity.read(createPaginationObject(null, null, filters));
      const response = this.isLoggedIn
        ? await this.$api.charity.read(
          createPaginationObject(null, null, filters),
        )
        : await this.$api.charity.readPublic(
          createPaginationObject(null, null, filters),
        );
      [this.charity] = response.charities;
    },
    async loadTypes() {
      if (!this.socialMediaTypes.length) this.$store.dispatch('general/getSocialMediaTypes');
    },
    async loadBackgroundImage() {
      if (!this.externalCharityId && !this.charityId) return;
      /* const filters = [
        {
          name: 'entityId',
          values: [this.externalCharityId ? this.externalCharityId.toString() : this.charityId.toString()],
        },
        {
          name: 'type',
          values: ['background-image'],
        },
      ];
      const response = await this.$api.image.readImages('charity', createPaginationObject(null, null, filters));
      const [firstImage] = response.images;
      this.backgroundImage = firstImage.image; */
      this.$emit('updateBackgroundImage', 'charity', (this.externalCharityId ? this.externalCharityId.toString() : this.charityId.toString()));
    },
    editFABClicked() {
      if (this.editing) {
        this.loadCharity();
        this.loadBackgroundImage();
      }
      this.editing = !this.editing;
    },
    editBackground() {
      this.$refs.backgroundImageSelectorDialog.openForm();
    },
    submitBackgroundImageChange() {
      this.$refs.backgroundImageSelector.submit();
    },
    closeDialogs() {
      this.$refs.backgroundImageSelectorDialog.closeForm();
      this.$refs.userImageSelectorDialog.closeForm();
    },
    openWindow(link, socialMediaTypeId = null) {
      if (socialMediaTypeId === this.socialMediaEmailId) window.open(`mailto:${link}?subject=I found your profile on BoardMatch`);
      else window.open(link);
    },
    checkExternalCharityId() {
      if (this.$route.query.chid) {
        this.externalCharityId = this.$route.query.chid;
        const query = { ...this.$route.query };
        delete query.chid;
        this.$router.replace({ query });
      } else this.externalCharityId = null;
    },
    processLoadedBoardMembers(members) {
      this.showBoardMembers = members && members.length > 0;
    },
    processLoadedCharityPosting(postings) {
      this.showCharityPostings = postings && postings.length > 0;
    },
  },
};
</script>
<style scoped>
.status-tile {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  margin:12px;
  width: fit-content;
  font-family: "Roboto", sans-serif;
}
.title-widget {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 12px;
}
.content-div {
  margin-top: 270px !important;
  margin-left: 2.5%;
  margin-right: 2.5%;
  z-index: 2;
  position: relative;
}
.widget {
  border-radius: 8px;
  padding: 12px;
}
.charity-fab {
  z-index: 3;
}
.edit-background-button {
  border-radius: 16px;
  border: 3px solid lightgray;
  background-color: transparent !important;
  width: 800px;
  height: 175px !important;
}

</style>
